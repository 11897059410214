import { createNotification } from '../ios/dynamic-island.js';

class OpenAI {
    constructor() {
        this.elements = {
            navigationBar: null,
            navigationBarContent: null,
            appContent: null,
            appToolbar: null,
            userInfo: null,
            userAvatar: null,
            userName: null,
            inputField: null
        };
        this.whitespace = /[ \t]+/g;
    }

    async init() {
        this._setElements();
        if (!this.elements.userInfo) {
            this._createUserInfo();
        }
        this._observeResize();
        this.conversationsnapshot = localStorage.getItem('conversationsnapshot');
        if (!this.conversationsnapshot) {
            localStorage.setItem('conversationsnapshot', '');
            this.conversationsnapshot = '';
        }
        if (this.conversationsnapshot === '' || document.querySelector('.user-name').textContent === 'OpenAI') {
            await this._loadChatConfigs();
            const userMessage = document.createElement("p");
            userMessage.classList.add("bubble", "user");
            userMessage.textContent = this._initOpeningLine();
            this.elements.appContent.appendChild(userMessage);
            this.conversationsnapshot += `\n${userMessage.textContent}`;
            this.sendMessage('', 0);
        }
    }

    async _loadChatConfigs() {
        const configspath = '/config/chat.config.json';
        const response = await fetch(configspath);
        const data = await response.json();
        this.configs = data.configs;
        this.characters = data.character;
        this.mention = new RegExp(`@(${this.characters.slice(1).flatMap(char => char.keywords).join("|")})\\b`, 'gi');
        this.lastcharacter = this._initCharacter().short;
        this.intro = `${data.prologue}`;
        this.places = data.places;
        this.cloths = data.cloths;
        this.activities = data.activities;
    }

    _initCharacter() {
        return this.characters[Math.floor(Math.random() * (this.characters.length - 1)) + 1];
    }

    _initOpeningLine() {
        this.area = Math.random() < 0.5 ? 'public' : 'residence';
        this.place = this.places[this.area][Math.floor(Math.random() * this.places[this.area].length)];
        this.activity = this.activities[this.area][this.place][Math.floor(Math.random() * this.activities[this.area][this.place].length)];
        this.cloth = this.cloths[this.area][Math.floor(Math.random() * this.cloths[this.area].length)];
        this.fabric = this.cloths.fabric[Math.floor(Math.random() * this.cloths.fabric.length)];
        this.color = this.cloths.colors[Math.floor(Math.random() * this.cloths.colors.length)];

        if (/(undressing)/g.test(this.activity)) {
            if (/(nude|naked|topless)/g.test(this.cloth)) {
                this.openingline = `[${this.place}][${this.lastcharacter} ${this.cloth}]`;
            } else {
                this.openingline = `[${this.place}][${this.lastcharacter} ${this.activity} her ${this.color} ${this.fabric} ${this.cloth}]`;
            }
        } else if (/(changing into)/g.test(this.activity)) {
            if (/(nude|naked|topless)/g.test(this.cloth)) {
                this.openingline = `[${this.place}][${this.lastcharacter} ${this.cloth}]`;
            } else {
                this.openingline = `[${this.place}][${this.lastcharacter} ${this.activity} a ${this.color} ${this.fabric} ${this.cloth}]`;
            }
        } else {
            if (/(nude|naked|topless)/g.test(this.cloth)) {
                this.openingline = `[${this.place}][${this.lastcharacter} ${this.activity} ${this.cloth}]`;
            } else {
                this.openingline = `[${this.place}][${this.lastcharacter} ${this.activity} in a ${this.color} ${this.fabric} ${this.cloth}]`;
            }
        }
        return this.openingline.replace(/charname/g, this.lastcharacter);
    }

    _setElements() {
        this.elements.navigationBar = document.querySelector('.navigation-bar');
        this.elements.navigationBarContent = this.elements.navigationBar.querySelector('.navigation-bar-content');
        this.elements.appContent = document.querySelector('.app-content');
        this.elements.appToolbar = document.querySelector('.app-toolbar');
        this.elements.userInfo = this.elements.navigationBarContent.querySelector('.user-info');
    }

    _createUserInfo() {
        this.elements.userInfo = document.createElement('div');
        this.elements.userAvatar = document.createElement('img');
        this.elements.userName = document.createElement('span');
        const accessoriesBar = document.createElement('div');
        this.elements.inputField = document.createElement('input');
        const toolbar = document.createElement('div');

        [this.elements.userInfo, this.elements.userAvatar, this.elements.userName, accessoriesBar, this.elements.inputField, toolbar]
            .forEach((element, index) => element.classList.add(['user-info', 'user-avatar', 'user-name', 'accessories-bar', 'input', 'toolbar'][index]));

        this.elements.userAvatar.src = '../assets/images/openai.jpeg';
        this.elements.userName.textContent = 'OpenAI';
        this.elements.userInfo.append(this.elements.userAvatar, this.elements.userName);
        this.elements.navigationBarContent.appendChild(this.elements.userInfo);

        accessoriesBar.appendChild(this.elements.inputField);
        this.elements.appToolbar.append(accessoriesBar, toolbar);
        this.elements.appToolbar.style.border = 'none';

        this.elements.inputField.addEventListener("keyup", (event) => {
            if (event.key === "Enter") {
                this.inputHandle();
            }
            if (event.altKey && event.key === "r") {
                this.sendMessage(`>rs`, 1);
            }
            if (event.altKey && event.key === "c") {
                this.elements.inputField.value = `>cheat: `;
            }
            if (event.altKey && event.key === "a") {
                this.lastcharacter = this.characters[3].short;
                this.sendMessage(``, 0);
            }
            if (event.altKey && event.key === "m") {
                this.lastcharacter = this.characters[1].short;
                this.sendMessage(``, 0);
            }
            if (event.altKey && event.key === "s") {
                this.lastcharacter = this.characters[2].short;
                this.sendMessage(``, 0);
            }
        });
    }

    _observeResize() {
        const resizeObserverTop = new ResizeObserver(entries => {
            for (const entry of entries) {
                const { height } = entry.contentRect;
                this.elements.appContent.style.paddingTop = `${height}px`;
            }
        });

        const resizeObserverBottom = new ResizeObserver(entries => {
            for (const entry of entries) {
                const { height } = entry.contentRect;
                const paddingBottom = height + 10;
                this.elements.appContent.style.paddingBottom = `${paddingBottom}px`;
            }
        });

        resizeObserverTop.observe(this.elements.navigationBar);
        resizeObserverBottom.observe(this.elements.appToolbar);
    }

    sanitizeString(input) {
        const tempDiv = document.createElement("div");
        return (tempDiv.innerText = input), DOMPurify.sanitize(tempDiv.innerText, {
            ALLOWED_TAGS: [],
            ALLOWED_ATTR: [],
            ALLOW_DATA_ATTR: false,
            ALLOW_UNKNOWN_PROTOCOLS: false
        });
    }

    inputHandle() {
        const playerinput = this.elements.inputField.value.trim();
        if (!playerinput) return;
        this.sendMessage(playerinput, 1);
    }

    parseMessage(str) {
        let purifiedinput = this.sanitizeString(str);
        if (/[a-zA-Z0-9]$/.test(purifiedinput)) {
            purifiedinput += '.';
        }
        this.characters.forEach(character => {
            if (character.keywords.some(keyword => purifiedinput.toLowerCase().includes(`@${keyword}`))) {
                this.lastcharacter = character.short;
            }
        });

        const nomention = purifiedinput.replace(this.mention, "$1");

        this.cheat = '';
        if (nomention.toLowerCase().startsWith("&gt;cheat:") || nomention.toLowerCase().startsWith(">cheat:")) {
            this.elements.inputField.value = '';
            const cheatText = nomention.substring(10);
            this.cheat = `\nINSTRUCTION:${cheatText}`;
            console.log(`cheat prompt:${cheatText}`);
            createNotification(1, `Cheat: ${cheatText}`, `../assets/images/openai.jpeg`);
        }
        else if (nomention.toLowerCase().startsWith("&gt;rs") || nomention.toLowerCase().startsWith(">rs")) {
            this.elements.appContent.innerHTML = '';
            this.lastcharacter = this._initCharacter().short;
            const userMessage = document.createElement("p");
            userMessage.classList.add("bubble", "user");
            userMessage.textContent = this._initOpeningLine();
            this.elements.appContent.appendChild(userMessage);
            this.elements.appContent.scrollTop = this.elements.appContent.scrollHeight;
            this.elements.inputField.value = '';
            this.conversationsnapshot = '';
            this.conversationsnapshot += `\n${userMessage.textContent}`;
            createNotification(1, 'Restarted...', `../assets/images/openai.jpeg`);
        }
        else {
            const userMessage = document.createElement("p");
            userMessage.classList.add("bubble", "user");
            userMessage.textContent = nomention;
            this.elements.appContent.appendChild(userMessage);
            this.elements.appContent.scrollTop = this.elements.appContent.scrollHeight;
            this.elements.inputField.value = '';

            this.conversationsnapshot += `\n${this.characters[0].short}: ${nomention}`;
        }
    }

    async sendMessage(str, int) {
        if (int !== 0) {
            this.parseMessage(str);
        }

        const requestBody = this._buildRequestBody();

        const requestOptions = {
            method: "POST",
            headers: {
                "Content-Type": "application/json; charset=utf-8",
                "Content-Security-Policy": "default-src 'self' https://api.openai.com"
            },
            body: JSON.stringify(requestBody)
        };

        try {
            let retryCount = 0;
            let response;

            do {
                response = await fetch("https://openai.neih.workers.dev/v1/completions", requestOptions);
                if (response.status === 429 || response.status === 401) {
                    retryCount++;
                } else {
                    break;
                }
            } while (retryCount < 10);

            const responsedata = await response.json();
            const responsetext = decodeURIComponent(responsedata.choices[0].text).replace(/^\n+(.*)/gm, '$1');
            const purified = this.sanitizeString(responsetext);
            const nomention = purified.replace(this.mention, "$1");
            const recipientMessage = document.createElement("p");
            recipientMessage.classList.add("bubble", "recipient");

            this.characters.forEach((character) => {
                if (character.short === this.lastcharacter) {
                    const { name, status, avatar, color } = character;
                    recipientMessage.textContent = nomention;
                    recipientMessage.style.backgroundColor = color;
                    this.elements.userAvatar.src = `../assets/images/${avatar}.jpeg`;
                    this.elements.userName.textContent = name;
                    createNotification(1, `${name}: ${nomention}`, `../assets/images/${avatar}.jpeg`);

                    this.conversationsnapshot += `\n${this.lastcharacter}: ${nomention.replace('<br>', '\n')}`;
                    localStorage.setItem('conversationsnapshot', this.conversationsnapshot);
                    this.sectionsOnSnapshot();
                }
            });

            this.elements.appContent.appendChild(recipientMessage);
            this.elements.appContent.scrollTop = this.elements.appContent.scrollHeight;

        } catch (error) { }
    }

    _buildRequestBody() {
        return {
            model: this.configs.model,
            prompt: `${this.intro}${this.conversationsnapshot ? this.conversationsnapshot : `\n${this._initOpeningLine()}`}${this.cheat ? this.cheat : ''}\n${this.lastcharacter}: `,
            temperature: this.configs.temperature,
            max_tokens: this.configs.max_tokens,
            top_p: this.configs.top_p,
            frequency_penalty: this.configs.frequency_penalty,
            presence_penalty: this.configs.presence_penalty,
            stop: this.characters.map(character => `${character.short}: `)
        };
    }

    sectionsOnSnapshot() {
        if (this.conversationsnapshot) {
            this.conversationsnapshot = this.conversationsnapshot.replace(this.whitespace, ' ');
        }
        this.conversationsnapshot = this.conversationsnapshot.split("\n").slice(Math.max(0, this.conversationsnapshot.split("\n").length - 100)).join("\n");
    }
}
export { OpenAI };
