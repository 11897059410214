import { OpenAI } from './openai/openai.js';
import { createNotification } from './ios/dynamic-island.js';

function appOpen(app) {
	if (app) {
		if (app === 'openai') {
			const ChatGPT = new OpenAI();
			ChatGPT.init();
			appScreenBuild();
		}

		const appWindow = document.querySelector('.app-screen');
		const homeIndicator = document.querySelector('.home-indicator');

		const animateOptions = {
			duration: 250,
			easing: 'ease-in',
			fill: 'both'
		};

		const AppOpenAnimation = appWindow.animate(
			[{
				transform: 'translateX(0px)',
				opacity: '0'
			}, {
				transform: 'translateX(-393px)',
				opacity: '1'
			}],
			animateOptions
		);

		const HomeEnableAnimation = homeIndicator.animate(
			[{
				transform: 'translateY(20px)',
				opacity: '0'
			}, {
				transform: 'translateY(0px)',
				opacity: '1'
			}],
			Object.assign({}, animateOptions, {
				duration: 350
			})
		);
	}
	return
}

function appClose() {
	const appWindow = document.querySelector('.app-screen');
	const homeIndicator = document.querySelector('.home-indicator');

	const animateOptions = {
		duration: 350,
		easing: 'ease-in',
		fill: 'both'
	};

	const AppCloseAnimation = appWindow.animate(
		[{
			transform: 'translateX(-393px)',
			opacity: '1'
		}, {
			transform: 'translateX(0px)',
			opacity: '0'
		}],
		animateOptions
	);

	const HomeDisableAnimation = homeIndicator.animate(
		[{
			transform: 'translateY(0px)',
			opacity: '1'
		}, {
			transform: 'translateY(20px)',
			opacity: '0'
		}],
		Object.assign({}, animateOptions, {
			duration: 250
		})
	);
}

function timeSync() {
	const clock = document.querySelectorAll(".clock");
	const now = new Date();
	let hours = now.getHours();
	let minutes = now.getMinutes();

	hours = hours.toString().padStart(2, "0");
	minutes = minutes.toString().padStart(2, "0");

	const time = `${hours}:${minutes}`;
	for (let i = 0; i < clock.length; i++) {
		clock[i].innerText = time;
	}
}

function homeScreenBuild() {
	const levels = document.querySelectorAll('.levels');
	for (let i = 0; i < levels.length; i++) {
		const spanElement = levels[i].querySelector('span');
		spanElement.textContent = 'lustline';
	}
}

function appsGridBuild() {
	const appContainer = document.querySelectorAll('.app-container');
	fetch('/config/apps.config.json')
		.then(response => response.json())
		.then(data => {
			for (let i = 0; i < data.length; i++) {
				const app = data[i];
				const slug = app.slug;
				const name = app.name;
				const icon = app.icon;
				const appIcon = document.createElement('span');
				const appName = document.createElement('label');
				appIcon.classList.add('app-icon');
				appName.classList.add('app-name');
				if (icon) {
					appIcon.style.backgroundImage = `url(${icon})`;
				}
				appName.textContent = name;
				appContainer[i].appendChild(appIcon);
				appContainer[i].appendChild(appName);
				if (slug) {
					appContainer[i].addEventListener('click', () => {
						appOpen(slug);
					});
				}
			}
		})
		.catch(error => console.log('Error:', error));
}

function dockAppsBuild() {
	fetch('/config/apps.config.json')
		.then(response => response.json())
		.then(data => {
			for (let i = 0; i < 4 && i < data.length; i++) {
				const app = data[i];
				const slug = app.slug;
				const icon = app.icon;
				const dock = document.querySelector('.dock');
				const appIcon = document.createElement('span');
				appIcon.classList.add('app-icon');
				if (icon) {
					appIcon.style.backgroundImage = `url(${icon})`;
				}
				dock.appendChild(appIcon);
				if (slug) {
					appIcon.addEventListener('click', () => {
						appOpen(slug);
					});
				}
			}
		})
		.catch(error => console.log('Error:', error));
}

function appScreenBuild() {
	const homeIndicator = document.querySelector('.home-indicator');
	homeIndicator.addEventListener('click', appClose);
}

homeScreenBuild();
appsGridBuild();
dockAppsBuild();
setInterval(timeSync, 1000);