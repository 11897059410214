export function createNotification(type, text, icon) {
	const dynamicIslands = document.querySelectorAll('.dynamic-island');

	for (let i = 0; i < dynamicIslands.length; i++) {
		let dynamicIsland = dynamicIslands[i];

		dynamicIsland.innerHTML = '';

		let notificationText = document.createElement('div'),
			notificationTextContent = document.createElement('span'),
			notificationIcon = document.createElement('div'),
			notificationIconImage = document.createElement('img');

		notificationText.classList.add('dynamic-island-notification-sm');
		notificationIcon.classList.add('dynamic-island-icon-sm');
		notificationTextContent.textContent = text;
		notificationIconImage.src = icon;
		notificationIcon.appendChild(notificationIconImage);
		notificationText.appendChild(notificationTextContent);
		dynamicIsland.appendChild(notificationText);
		dynamicIsland.appendChild(notificationIcon);
	}
}